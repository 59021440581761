import { Icons } from "@/components/ui/icons";
import { getReferenceTitleSource } from "@/utils/getReferenceTitleSource";
import { FileIcon } from "../../AgentForm/components/Knowledge/components/FileIcon";

import type { Reference } from "@/types/trace";
import type { TitleAndSource } from "@/utils/getReferenceTitleSource";
import type { EmbedKnowledges } from "@/types/agent";

type ReferencesProps = {
  references: Reference[];
  setDocumentInPreview?: React.Dispatch<React.SetStateAction<EmbedKnowledges | null>>;
  setDocPreviewPage?: React.Dispatch<React.SetStateAction<number | null>>;
};

const convertReferenceToEmbeddedKnowledge = ({ filename, source, title }: TitleAndSource): EmbedKnowledges => ({
  _id: "",
  creator: "",
  embedIdList: [""],
  knowledgeType: "DOC",
  source: source!,
  filename: filename!,
  description: title || "",
  content: "",
  parentId: "",
  status: "success",
  createdAt: "",
  updatedAt: "",
});

const checkForPossibleDocPreview = (filename?: string): boolean => {
  if (!filename) {
    return false;
  }

  const fileFormat = filename.slice(filename.lastIndexOf("."));
  const allowedFileFormats = [".pdf", ".xls", ".xlsx", ".png", ".jpg", ".jpeg", ".webp", ".gif", ".bmp"];
  return allowedFileFormats.includes(fileFormat);
};

export const References = ({ references, setDocumentInPreview, setDocPreviewPage }: ReferencesProps) => {
  if (!references.length) {
    return null;
  }
  const titleSourceReferences = getReferenceTitleSource(references);
  const filteredReferences = titleSourceReferences.filter(
    reference => reference.title !== "FAQ" && reference.source && reference.title
  );

  if (!filteredReferences.length) {
    return null;
  }

  return (
    <ol className="my-1 ml-[50px] flex w-[90%] list-none flex-col rounded-[4px] bg-references p-4 font-medium shadow-lg">
      <div className="flex pl-1 text-xs uppercase text-primary-black">References:</div>
      {filteredReferences.map(reference => (
        <li
          key={`${reference?.content} ${reference?.filename} ${reference?.source}`}
          className="mt-[-12px] cursor-pointer pt-6"
        >
          {reference?.source && setDocumentInPreview && checkForPossibleDocPreview(reference?.filename) ? (
            <button
              onClick={() => {
                setDocumentInPreview(convertReferenceToEmbeddedKnowledge(reference));
                setDocPreviewPage && setDocPreviewPage(reference.page || null);
              }}
              className="flex cursor-pointer items-start items-center gap-[6px] no-underline decoration-1 hover:underline"
            >
              <div>
                {reference.filename ? (
                  <FileIcon fileName={reference.filename} className="h-[21px] w-[24px]" />
                ) : (
                  <Icons.Link className="h-[21px] w-[24px]" />
                )}
              </div>
              <p className="text-xs font-semibold leading-4 text-references-text">
                {reference.filename || reference.title}
              </p>
            </button>
          ) : (
            <a
              href={addPrefixToUrl(reference.source || "")}
              target="_blank"
              rel="noopener noreferrer"
              className="flex cursor-pointer items-start items-center gap-[6px] no-underline decoration-1 hover:underline"
            >
              <div>
                {reference.filename ? (
                  <FileIcon fileName={reference.filename} className="h-[21px] w-[24px]" />
                ) : (
                  <Icons.Link className="h-[21px] w-[24px]" />
                )}
              </div>
              <p className="text-xs font-semibold leading-4 text-references-text">
                {reference.filename || reference.title}
              </p>
            </a>
          )}
        </li>
      ))}
    </ol>
  );
};

const addPrefixToUrl = (url: string) => {
  if (!url.startsWith("http://") && !url.startsWith("https://")) {
    return "https://" + url; // Add "https://" prefix if not present
  }
  return url; // URL already has a prefix
};
