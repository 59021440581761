import { Carousel, CarouselContent, CarouselDots, CarouselItem } from "@/components/ui/carousel";
import { WorkspaceWhatsNewArticleCard } from "./WorkspaceWhatsNewArticleCard";
import AutoHeight from "embla-carousel-auto-height";

const ARTICLES = [
  {
    title: "How to Create a Lead Generation AI Agent Chatbot on AgentX - Formless and Straight to Your Mailbox",
    img: "https://assets-global.website-files.com/65173d09bdeae80b1429fa88/662f763ea65512c142b35b12_mainimage.webp",
    description:
      "Lead generation chatbot powered by AI can operate continuously to handle customer queries and capture inbound leads directly. AgentX provides formless interaction where the chatbot gathers contact information through natural language conversations. The setup process involves four steps: creating an AI agent, provide customized knowledge to AI, enable lead generation option, and deploying the agent on your website.",
    url: "https://www.agentx.so/post/how-to-create-a-lead-generation-ai-agent-chatbot-on-agentx---formless-and-straight-to-your-mailbox",
  },
  {
    title: "Can Chatbots Read PDF? Uncovering The Technology Behind",
    img: "https://assets-global.website-files.com/65173d09bdeae80b1429fa88/661080f61f23814c6ea2ef87_AI-PDF-image.jpg",
    description:
      "Do you want to take your reading experience to the next level? If yes, then learn more about the technology and find your favorite ones.",
    url: "https://www.agentx.so/post/can-chatbots-read-pdf-uncovering-the-technology-behind-2",
  },
  {
    title: "AI Agent Training 101: Teaching AI to Understand Your Business",
    img: "https://assets-global.website-files.com/65173d09bdeae80b1429fa88/66054efa7109e4b4757b4ac2_trainign%20logo2-p-1600.webp",
    description:
      "In today's digital age, chatbots have become an essential part of customer engagement, automating responses, and offering support around the clock. However, not all chatbots are created equal. The key to a truly effective chatbot lies in its training – the process of imparting industry-specific knowledge and expertise. In this guide, we will explore the art of chatbot training and its significance in providing tailored solutions to your clients.",
    url: "https://www.agentx.so/post/ai-agent-training-101-teaching-ai-to-understand-your-business",
  },
];

export const WorkspaceWhatsNewCarousel = () => {
  return (
    <Carousel
      className="h-auto max-w-none"
      opts={{
        loop: true,
      }}
      plugins={[AutoHeight()]}
    >
      <CarouselContent>
        <CarouselItem key="howtobuildvideo" containerClassName="h-fit basis-full">
          <div className="mb-1 overflow-y-hidden rounded-md border border-neutral-300">
            <div className="bg-neutral-100 px-4 pb-2 pt-4">
              <h6 className="mb-2 text-base font-bold text-neutral-750">How to build an Agent</h6>
            </div>
            <iframe
              className="aspect-video w-full"
              src="https://player.vimeo.com/video/912432330?title=0&amp;byline=0&amp;portrait=0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
            ></iframe>
          </div>
        </CarouselItem>
        {ARTICLES.map(article => (
          <CarouselItem key={article.title} containerClassName="h-fit basis-full">
            <WorkspaceWhatsNewArticleCard {...article} />
          </CarouselItem>
        ))}
      </CarouselContent>

      <CarouselDots className="relative left-1/2 top-0 mt-4" />
    </Carousel>
  );
};
